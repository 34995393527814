import axios from "axios";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(function(response) {
    if (!response.data) {
        console.log("Error! No data in response.");
        return null;
    }

    return response;
}, function(error) {
    console.log(error);
    return null;
});

export default axios;