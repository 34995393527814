import Auth from "./pages/Auth";
import BackgroundFigures from "./components/BackgroundFigures";
import styled from "styled-components";

const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;
`;

function App() {
    return (
        <AppWrapper>
            <BackgroundFigures/>

            <ContentWrapper>
                <Auth/>
            </ContentWrapper>
        </AppWrapper>
    );
}

export default App;