import axios from "../middleware/axios";
import getUrlFromPath from "../utils/getUrlFromPath";
import {authPaths} from "../config/paths";

export function getRedirect(urlParameters) {
    return axios({
        method: "GET",
        url: getUrlFromPath(authPaths.GET_REDIRECT),
        params: {
            scope: urlParameters.scope,
            state: urlParameters.state,
            response_type: urlParameters.response_type,
            client_id: urlParameters.client_id,
            redirect_uri: urlParameters.redirect_uri
        }
    });
}