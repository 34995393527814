import * as React from "react"

const SvgComponent = (props) => (
    <svg
        width={34}
        height={16}
        fill="none"
        {...props}
    >
        <path
            d="M10 2 3 8l7 6m14 0 7-6-7-6m-5 0-5 13"
            stroke="url(#a)"
            strokeWidth={3}
        />
        <defs>
            <linearGradient
                id="a"
                x1={3}
                y1={2}
                x2={31}
                y2={15}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF9356" />
                <stop offset={1} stopColor="#FFDE89" />
            </linearGradient>
        </defs>
    </svg>
)

export default SvgComponent