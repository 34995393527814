import axios from "../middleware/axios";
import jsonToFormData from "../utils/jsonToFormData";
import getUrlFromPath from "../utils/getUrlFromPath";
import {authPaths} from "../config/paths";

export function getJWT(username, password) {
    return axios({
        method: "POST",
        url: getUrlFromPath(authPaths.GET_JWT),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: createFormData(username, password)
    });
}

function createFormData(username, password) {
    const authData = {
        username,
        password
    };

    return jsonToFormData(authData);
}