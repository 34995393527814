import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Workspace from "../../assets/icons/Workspace";
import {getJWT} from "../../actions/getJWT";
import getUrlParameter from "../../utils/getUrlParameter";
import axios from "../../middleware/axios";
import {getScopeDescription} from "../../actions/getScopeDescription";
import {getClientDescription} from "../../actions/getClientDescription";
import AuthFields from "../../components/AuthFields";
import AuthConfirm from "../../components/AuthConfirm";
import {getRedirect} from "../../actions/getRedirect";

// перезалить на сервак

const AuthPageWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const TitleTextWrapper = styled.div`
  margin-left: 15px;
  font-weight: 700;
  font-size: 14px;
  color: black;
`;

const Auth = () => {
    const [urlParameters, setUrlParameters] = useState({});
    const [username, setUsername] = useState('icektor@gmail.com');
    const [password, setPassword] = useState('qwerty123');
    const [fieldsError, setFieldsError] = useState(false);
    const [scopeDescription, setScopeDescription] = useState({});
    const [clientDescription, setClientDescription] = useState({});
    const [authPart, setAuthPart] = useState("fields");

    useEffect(() => {
        const urlString = window.location.href;

        setUrlParameters({
            scope: getUrlParameter("scope", urlString),
            state: getUrlParameter("state", urlString),
            response_type: getUrlParameter("response_type", urlString),
            client_id: getUrlParameter("client_id", urlString),
            redirect_uri: getUrlParameter("redirect_uri", urlString)
        });
    }, []);

    async function startAuth() {
        const successJwtRequest = await handleJWT();
        if (!successJwtRequest) {
            return;
        }

        const successScopeRequest = await handleScope();
        if (!successScopeRequest) {
            return;
        }

        await handleClient();
    }

    async function handleJWT() {
        const jwtResponse = await getJWT(username, password);

        if (!jwtResponse) {
            setFieldsError(true);
            setTimeout(() => setFieldsError(false), 3000);
            return null;
        }

        const jwt = jwtResponse.data.access_token;
        axios.defaults.headers["authorization"] = "Bearer " + jwt;
        return jwt;
    }

    async function handleScope() {
        const scopeResponse = await getScopeDescription(urlParameters.scope);

        if (!scopeResponse) {
            return null;
        }

        setScopeDescription(scopeResponse.data);
        return scopeResponse.data;
    }

    async function handleClient() {
        const clientResponse = await getClientDescription(
            urlParameters.client_id
        );

        if (!clientResponse) {
            return;
        }

        setClientDescription(clientResponse.data);
        setAuthPart("confirm");
    }

    function declineAuth() {
        setAuthPart("fields");
    }

    async function confirmAuth() {
        const response = await getRedirect(urlParameters);
        if (response) {
            window.location.href = response.data;
        }
    }

    // function checkDescriptionsReady() {
    //     return !(Object.keys(scopeDescription).length === 0 ||
    //         Object.keys(clientDescription).length === 0);
    // }

    return (
        <AuthPageWrapper>
            <TitleWrapper>
                <Workspace/>
                <TitleTextWrapper>Workspace</TitleTextWrapper>
            </TitleWrapper>

            {authPart === "fields" &&
                <AuthFields
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                    fieldsError={fieldsError}
                    startAuth={startAuth}
                />
            }

            {authPart === "confirm" &&
                <AuthConfirm
                    clientDescription={clientDescription}
                    scopeDescription={scopeDescription}
                    declineAuth={declineAuth}
                    confirmAuth={confirmAuth}
                />
            }
        </AuthPageWrapper>
    );
};

export default Auth;