import React from 'react';
import Input from "../UI/Input";
import Button from "../UI/Button";
import styled from "styled-components";

const InputsWrapper = styled.div`
  margin-bottom: 56px;
`;

const InputWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const AuthFields = (props) => {
    const {
        username,
        setUsername,
        password,
        setPassword,
        fieldsError,
        startAuth
    } = props;

    return (
        <div>
            <InputsWrapper>
                <InputWrapper>
                    <Input
                        fullWidth
                        value={username}
                        error={fieldsError}
                        placeholder="Почта"
                        onChange={(value) => setUsername(value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Input
                        fullWidth
                        value={password}
                        error={fieldsError}
                        type="password"
                        placeholder="Пароль"
                        onChange={(value) => setPassword(value)}
                    />
                </InputWrapper>
            </InputsWrapper>

            <Button
                autoFocus
                fullWidth
                onClick={startAuth}
            >
                <span>Войти</span>
            </Button>
        </div>
    );
};

export default AuthFields;