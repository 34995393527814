const theme = {
    sizes: {
        fontWeight: {
            bold: "700"
        },

        borderRadius: {
            default: "4px"
        }
    },

    colors: {
        orange: "#ffab5d",
        red: "#f46767",
        blue: "#56b8ff",
        violet: "#8c56ff",
        green: "#7cdc80",
        darkBlue: "#305067",
        turquoise: "#63aaa6",
        grey: "#5687AD",
        lightgrey: "#a1bcd1",

        font: {
            dark: "#3d3d3d",
            light: "#ffffff",
            grey: "rgba(86, 135, 173, 0.5)"
        },

        backgroundColor: "rgba(209, 212, 255, 0.8)",

        selection: {
            backgroundColor: "rgba(86, 184, 255, 0.5)"
        },

        elements: {
            backgroundColor: "rgba(250, 250, 250, 0.35)",

            input: {
                placeholderColor: "rgba(0, 38, 68, 0.8)"
            }
        },
    }
}

export default theme;