import React, {useState} from 'react';
import theme from "../../../config/theme";
import styled from "styled-components";

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.fullWidth ? "100%" : "auto"};
  padding: 6px;
  background: ${props => props.backgroundColor || theme.colors.blue};
  color: ${props => props.textColor || theme.colors.font.light};
  outline: none;
  border: none;
  border-radius: ${theme.sizes.borderRadius.default};
  box-shadow: ${props => props.active
          ? "3px 3px 15px 1px " + (props.backgroundColor || theme.colors.blue)
          : "none"
  };
  transition: box-shadow 0.25s ease;
  cursor: pointer;
`;

const Button = (props) => {
    const {
        autoFocus,
        fullWidth,
        backgroundColor,
        textColor,
        children,
        onClick
    } = props;

    const [active, setActive] = useState(false);

    return (
        <ButtonWrapper
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            backgroundColor={backgroundColor}
            textColor={textColor}
            active={active}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onClick={onClick}
        >
            {children}
        </ButtonWrapper>
    );
};

export default Button;
