import {BASE_URL_LOCAL, BASE_URL_SERVER} from "../config/consts";
import checkLocation from "./checkLocation";

export default function getUrlFromPath(path) {
    let url;
    if (checkLocation() === "local") {
        url = BASE_URL_LOCAL + path;
    } else {
        url = BASE_URL_SERVER + path;
    }

    return url;
}