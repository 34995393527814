import React from 'react';
import Button from "../UI/Button";
import theme from "../../config/theme";
import styled from "styled-components";

const ClientDescriptionWrapper = styled.div`
  margin-bottom: 15px;
`;

const ScopeDescriptionWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AuthConfirm = (props) => {
    const {
        clientDescription,
        scopeDescription,
        declineAuth,
        confirmAuth
    } = props;

    return (
        <div>
            <ClientDescriptionWrapper>
                {clientDescription.name} запрашивает доступ к вашим ресурсам:
            </ClientDescriptionWrapper>

            <ScopeDescriptionWrapper>
                {Object.keys(scopeDescription).map(element =>
                    <p key={element}>- {scopeDescription[element]}</p>
                )}
            </ScopeDescriptionWrapper>

            <ButtonsWrapper>
                <Button
                    backgroundColor={theme.colors.font.grey}
                    onClick={declineAuth}
                >
                    Отклонить
                </Button>

                <Button
                    autoFocus
                    onClick={confirmAuth}
                >
                    Разрешить
                </Button>
            </ButtonsWrapper>
        </div>
    );
};

export default AuthConfirm;