import React from 'react';
import styled from "styled-components";
import theme from "../../config/theme";

const BackgroundFiguresWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: ${theme.colors.backgroundColor};
`;

const VioletCircle = styled.div`
  position: absolute;
  top: -10%;
  left: 30%;
  height: 35%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${theme.colors.violet};
  filter: blur(20vh);
`;

const RedCircle = styled.div`
  position: absolute;
  top: 100px;
  right: -10%;
  height: 45%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${theme.colors.red};
  filter: blur(23vh);
`;

const OrangeCircle = styled.div`
  position: absolute;
  bottom: -10%;
  right: 25%;
  height: 35%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${theme.colors.orange};
  filter: blur(20vh);
`;

const BlueCircle = styled.div`
  position: absolute;
  bottom: -10%;
  left: -10%;
  height: 60%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${theme.colors.blue};
  filter: blur(25vh);
`;

const BackgroundFigures = () => {
    return (
        <BackgroundFiguresWrapper>
            <VioletCircle/>
            <RedCircle/>
            <OrangeCircle/>
            <BlueCircle/>
        </BackgroundFiguresWrapper>
    );
};

export default BackgroundFigures;